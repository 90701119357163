import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { window } from "browser-monads"
import { Icon } from "semantic-ui-react"
import "semantic-ui-css/semantic.min.css"
import "../css/header.css"

const Header = props => {
  const [mobile, setMobile] = useState(window.innerWidth < 680 ? true : false)
  const [showMenu, setShowMenu] = useState(false)

  const checkMobile = () => {
    setMobile(window.innerWidth < 680 ? true : false)
  }

  useEffect(() => {
    checkMobile()
    window.addEventListener("resize", checkMobile, true)

    return () => {
      window.removeEventListener("resize", checkMobile, true)
    }
  }, [])

  return (
    <header className={props.color === "white" ? "white" : "purple"}>
      <Link to="/">
        <div id="headerLogo" onClick={() => {}}>
          Paccione <br /> Design &amp; Development
        </div>
      </Link>
      <div id="headerLinkWrap">
        {mobile ? (
          <Icon
            name={showMenu ? "close" : "bars"}
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          />
        ) : (
          <div
            style={{
              display: `flex`,
              alignContent: `space-between`,
            }}
          >
            {!window.location.href.includes("/projects-index") && (
              <Link to="/projects-index" style={{ marginRight: "15px" }}>
                Projects
              </Link>
            )}
            {!window.location.href.includes("/software-index") && (
              <Link to="/software-index" style={{ marginRight: "15px" }}>
                Software
              </Link>
            )}
            {!window.location.href.includes("/freeware-index") && (
              <Link to="/freeware-index" style={{ marginRight: "15px" }}>
                Freeware
              </Link>
            )}
            {!window.location.href.includes("/team") && (
              <Link to="/team">Team</Link>
            )}
          </div>
        )}
      </div>
      {showMenu && (
        <div id="mobile-menu">
          {!window.location.href.includes("/projects-index") && (
            <Link to="/projects-index">
              Projects
            </Link>
          )}
          {!window.location.href.includes("/software-index") && (
            <Link to="/software-index">
              Software
            </Link>
          )}
          {!window.location.href.includes("/freeware-index") && (
            <Link to="/freeware-index">
              Freeware
            </Link>
          )}
          {!window.location.href.includes("/team") && (
            <Link to="/team">Team</Link>
          )}
        </div>
      )}
    </header>
  )
}

export default Header
